.receipt {
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  width: 300px;
  margin: auto;
}

.text-item {
  /* Styling for text items */
}

.spacing {
  display: block;
}

.spacing-10 {
  margin-top: 5px;
}

.spacing-20 {
  margin-top: 10px;
}

.spacing-40 {
  margin-top: 20px;
}
.w-33 {
  width: 32% !important;
}

/* Add more spacing classes as needed */

.barcode-container {
  text-align: left;
  background: transparent; /* Ensure no background */
  height: 50px;

}
